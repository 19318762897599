import React from 'react';
import { graphql } from 'gatsby';

import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import PostHeader from '@components/blog/postHeader';
import RelatedPosts from '@components/blog/relatedPosts';
import PortableTextBlock from '@components/sanity/portableTextBlock';

const BlogPost = ({ data: { page, relatedPosts } }) => {
  const { post, title, postDate, excerpt, seo } = page || {};
  return (
    <Layout>
      <PageMeta {...seo} />
      <PostHeader title={title} postDate={postDate} excerpt={excerpt} />
      <div className="px-gutter">
        <PortableTextBlock text={post} className="max-w-6xl mx-auto" />
      </div>
      <RelatedPosts posts={relatedPosts?.nodes} />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query blogPostQuery($slug: String!, $created: Date!) {
    page: sanityBlogPost(slug: { current: { eq: $slug } }) {
      title
      excerpt

      seo {
        ...SEO
      }
      postDate(formatString: "D MMM YYYY")
      post: _rawPost(resolveReferences: { maxDepth: 10 })
    }
    relatedPosts: allSanityBlogPost(
      sort: { order: DESC, fields: _createdAt }
      limit: 3
      filter: { _createdAt: { gte: $created } }
    ) {
      nodes {
        key: _key
        title
        excerpt
        postDate(formatString: "D MMM YYYY")
        cardImage {
          ...ImageWithPreview
        }
        category {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
