import React from 'react';

const PostHeader = ({ title, excerpt, postDate }) => {
  return (
    <div className="px-gutter pt-space-m pb-space-s text-center grid gap-y-[18px]">
      <h5 className="uppercase font-bold text-lg">{postDate}</h5>
      <h1 className="text-3xl text-red md:text-5xl lg:text-6xl">{title}</h1>
      <h4 className="text-lg max-w-3xl mx-auto md:text-2xl">{excerpt}</h4>
    </div>
  );
};

export default PostHeader;
