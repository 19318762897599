import React from 'react';

import Card from './blogCard';

const RelatedPosts = ({ posts }) => {
  return (
    <div className="px-gutter py-32">
      <h2 className="blockH6 text-center text-red pb-16">Other News</h2>
      <div className="grid grid-cols-1 max-w-6xl mx-auto gap-10 md:grid-cols-3">
        {posts.map((item) => (
          <Card {...item} />
        ))}
      </div>
    </div>
  );
};

export default RelatedPosts;
